class SearchContainer {
  constructor(element) {
    this.element = element
    this.filtersToggle = document.querySelector(".js-filters-toggle")

    // Sidebar
    this.sidebar = this.element.querySelector("#search-sidebar")
    this.searchForm = this.sidebar.querySelector("form")
    this.savedSearchesCard = this._initializeSavedSearchesCard()

    this.searchFiltersManager = new SearchFiltersManager(this.sidebar)
    this.filterOptionsModal = new FilterOptionsModal(
      this.element.querySelector("#js-filter-options-modal"),
      this.searchFiltersManager,
    )

    // Results Container
    this.resultsContainer = this.element.querySelector("#search-results-container")
    this.resultsContainerClassesWithSidebar = this.resultsContainer.dataset.classesWithSidebar.split(" ")
    this.resultsContainerClassesWithoutSidebar = this.resultsContainer.dataset.classesWithoutSidebar.split(" ")

    this.activeFiltersManager = new ActiveFiltersManager(
      this.element,
      this.searchFiltersManager.activeFilters(),
    )

    this._setupEvents()
  }

  _activeFilterCleared() {
    this.searchForm.submit()
  }

  _initializeSavedSearchesCard() {
    const savedSearchesCardElement = this.sidebar.querySelector(".search-saved-searches-card")

    return savedSearchesCardElement ? new SavedSearchesCard(savedSearchesCardElement) : null
  }

  _setupEvents() {
    window.$(this.sidebar).on("hide.bs.collapse", this._hideSidebar.bind(this))
    window.$(this.sidebar).on("show.bs.collapse", this._showSidebar.bind(this))

    this.filtersToggle.addEventListener("click", this._filtersToggleClicked.bind(this))

    this.activeFiltersManager.filters().forEach(activeFilter => {
      activeFilter.element.addEventListener("activeFilterClearedEvent", this._activeFilterCleared.bind(this))
    })
  }

  _hideSidebar() {
    this.resultsContainer.classList.add(...this.resultsContainerClassesWithoutSidebar)
    this.resultsContainer.classList.remove(...this.resultsContainerClassesWithSidebar)
  }

  _showSidebar() {
    this.resultsContainer.classList.add(...this.resultsContainerClassesWithSidebar)
    this.resultsContainer.classList.remove(...this.resultsContainerClassesWithoutSidebar)
  }

  _filtersToggleClicked() {
    if (this.filtersToggle.classList.contains("collapsed")) {
      this.filtersToggle.querySelector("strong").textContent = "Hide Filters"
      this.filtersToggle.querySelector(".fas").classList.remove("fa-plus")
      this.filtersToggle.querySelector(".fas").classList.add("fa-times")
      this.filtersToggle.classList.remove("btn-primary")
      this.filtersToggle.classList.add("btn-default")
    } else {
      this.filtersToggle.querySelector("strong").textContent = "Show Filters"
      this.filtersToggle.querySelector(".fas").classList.remove("fa-times")
      this.filtersToggle.querySelector(".fas").classList.add("fa-plus")
      this.filtersToggle.classList.remove("btn-default")
      this.filtersToggle.classList.add("btn-primary")
    }
  }
}

class SavedSearchesCard {
  constructor(element) {
    this.element = element
    this.saveSearchBtn = this.element.querySelector(".js-save-search-btn")
    this.savedSearchManager = new SavedSearchManager(this.element.querySelector(".list-group"))

    this._setupEvents()
  }

  _setupEvents() {
    // The saveSearchBtn will only appear if there is an active search
    this.saveSearchBtn?.addEventListener("ajax:success", this._savedSearchCreated.bind(this))
  }

  _savedSearchCreated() {
    const modal = document.querySelector(".js-async-modal")

    modal.addEventListener("ajax:success", (event) => {
      this.savedSearchManager.insert(event.detail[0].body.innerHTML)
      $(modal).modal("hide")
    })

    // We add a listener for `ajax:complete` rather than `ajax:error` because when `error` is fired
    // the original modal has not yet been replaced with the validation errors. This occurs before
    // `complete` fires which is fired after `error`. We can then attach the `success` handler to the
    // newly returned modal.
    //
    modal.addEventListener("ajax:complete", (event) => {
      if (event.detail[0].status == 422) {
        this._savedSearchCreated()
      }
    })
  }
}

class SavedSearchManager {
  constructor(savedSearchContainer) {
    this.savedSearchContainer = savedSearchContainer

    this._initializeSavedSearches()
  }

  insert(HTML) {
    this._initializeSavedSearch(this.parse(HTML))
    this._sort()
  }

  parse(HTML) {
    return new DOMParser().parseFromString(HTML, "text/html").querySelector(".js-saved-search")
  }

  update(savedSearch, HTML) {
    this.delete(savedSearch)
    this.insert(HTML)
  }

  delete(savedSearch) {
    savedSearch.remove()
    delete this.collection[savedSearch.id]
  }

  searches() {
    return Object.values(this.collection)
  }

  _initializeSavedSearches() {
    this.collection = {}

    // The user cannot modify Default Saved Searches, so there is no need to initialize a SavedSearch
    // object for them, they can be ignored.
    this.savedSearchContainer.querySelectorAll(".js-saved-search:not(.js-saved-search-default)")
      .forEach(savedSearch => this._initializeSavedSearch(savedSearch))
  }

  _initializeSavedSearch(savedSearchElement) {
    const savedSearch = new SavedSearch(savedSearchElement, this)

    this.collection[savedSearch.id] = savedSearch

    return savedSearch
  }

  _sort() {
    this.searches().forEach(savedSearch => savedSearch.remove())
    this.searches().sort((a, b) => a.name > b.name ? 1 : -1)
      .forEach(savedSearch => this.savedSearchContainer.appendChild(savedSearch.element))
  }
}

class SavedSearch {
  constructor(element, savedSearchManager) {
    this.element = element
    this.savedSearchManager = savedSearchManager

    this.id = this.element.dataset.id
    this.queryLink = this.element.querySelector(".js-saved-search-name")
    this.name = this.queryLink.textContent
    this.editLink = this.element.querySelector(".js-saved_search-edit-link")
    this.deleteLink = this.element.querySelector(".js-saved-search-delete-link")

    // We manually enable the tooltip here so when a SavedSearch is added or updated via the UI
    // the tooltip will display correctly.
    //
    $(this.queryLink).tooltip("enable")

    this._setupEvents()
  }

  remove() {
    this.element.remove()
  }

  _setupEvents() {
    this.editLink.addEventListener("ajax:success", this._edited.bind(this))
    this.deleteLink.addEventListener("ajax:success", this._deleted.bind(this))
  }

  _edited() {
    const modal = document.querySelector(".js-async-modal")

    modal.addEventListener("ajax:success", (event) => {
      this.savedSearchManager.update(this, event.detail[0].body.innerHTML)
      $(modal).modal("hide")
    })

    // We add a listener for `ajax:complete` rather than `ajax:error` because when `error` is fired
    // the original modal has not yet been replaced with the validation errors. This occurs before
    // `complete` fires which is fired after `error`. We can then attach the `success` handler to the
    // newly returned modal.
    //
    modal.addEventListener("ajax:complete", (event) => {
      if (event.detail[0].status == 422) {
        this._edited()
      }
    })
  }

  _deleted() {
    this.savedSearchManager.delete(this)
  }
}

class ActiveFiltersManager {
  constructor(searchContainer, activeSearchFilters) {
    this._initializeActiveFilters(searchContainer, activeSearchFilters)
  }

  filters() {
    return Object.values(this.activeFilters)
  }

  _initializeActiveFilters(searchContainer, activeSearchFilters) {
    this.activeFilters = {}

    activeSearchFilters.forEach(searchFilter => {
      const activeFilterElement = searchContainer
        .querySelector(`.active-filter[data-search-filter-field="${searchFilter.field}"]`)
      const activeFilter = new ActiveFilter(activeFilterElement, searchFilter)

      this.activeFilters[searchFilter.id] = activeFilter
    })
  }
}

class ActiveFilter {
  constructor(element, searchFilter) {
    this.element = element
    this.searchFilter = searchFilter
    this.clearButton = this.element.querySelector(".active-filter-clear-btn")

    this._setupEvents()
  }

  _setupEvents() {
    this.activeFilterClearedEvent = new CustomEvent("activeFilterClearedEvent")
    this.clearButton.addEventListener("click", this._clearButtonClicked.bind(this))
  }

  _clearButtonClicked() {
    this.searchFilter.clear()
    this.searchFilter.disable()
    this.element.dispatchEvent(this.activeFilterClearedEvent)
  }
}

class FilterOptionsModal {
  constructor(modal, searchFiltersManager) {
    this.modal = modal
    this.optionsCount = this.modal.querySelector(".js-filter-options-count span")
    this.disableAllButton = this.modal.querySelector(".js-filter-options-disable-all-button")
    this.saveButton = this.modal.querySelector(".js-filter-options-save-button")

    this.searchFiltersManager = searchFiltersManager
    this.filterOptionsManager = new FilterOptionsManager(
      this.modal.querySelector(".js-filter-options"),
      this.searchFiltersManager.filters(),
    )

    this._setupEvents()
  }

  _disableAllButtonClicked() {
    this.filterOptionsManager.checkedOptions().forEach(option => option.check(false))
  }

  _filterOptionChanged() {
    this._toggleDisableAllButton()
    this._updateSelectedOptionsCount()
  }

  _saveButtonClicked() {
    this.searchFiltersManager.collapsibleFilters().forEach(searchFilter => {
      const filterOption = this.filterOptionsManager.optionFor(searchFilter)
      searchFilter.toggle(filterOption.isChecked())
    })
  }

  _setupEvents() {
    $(this.modal).on("show.bs.modal", this._showModal.bind(this))
    this.disableAllButton.addEventListener("click", this._disableAllButtonClicked.bind(this))
    this.saveButton.addEventListener("click", this._saveButtonClicked.bind(this))

    this.filterOptionsManager.options().map(option => option.checkbox)
      .forEach(checkbox => checkbox.addEventListener("change", this._filterOptionChanged.bind(this)))
  }

  _showModal() {
    this._updateSelectedOptionsCount()
    this._updateFilterOptionCheckboxes()
    this._toggleDisableAllButton()
  }

  _toggleDisableAllButton() {
    this.disableAllButton.disabled = this.filterOptionsManager.checkedOptions().length === 0
  }

  _updateFilterOptionCheckboxes() {
    this.searchFiltersManager.collapsibleFilters().forEach(searchFilter => {
      this.filterOptionsManager.optionFor(searchFilter).check(searchFilter.visible())
    })
  }

  _updateSelectedOptionsCount() {
    this.optionsCount.textContent = this.filterOptionsManager.checkedOptions().length
  }
}

class FilterOptionsManager {
  constructor(optionsContainer, searchFilters) {
    this._initializeOptions(optionsContainer, searchFilters)
  }

  checkedOptions() {
    return this.options().filter(option => option.isChecked())
  }

  options() {
    return Object.values(this.filterOptions)
  }

  optionFor(searchFilter) {
    return this.filterOptions[searchFilter.id]
  }

  _initializeOptions(optionsContainer, searchFilters) {
    this.filterOptions = {}

    searchFilters.forEach((searchFilter) => {
      const filterOptionElement = optionsContainer
        .querySelector(`.js-filter-option[data-search-filter-field="${searchFilter.field}"]`)

      if (filterOptionElement !== null) {
        const filterOption = new FilterOption(filterOptionElement)

        this.filterOptions[searchFilter.id] = filterOption
      }
    })
  }
}

class FilterOption {
  constructor(element) {
    this.checkbox = element.querySelector("input[type='checkbox']")
  }

  isChecked() {
    return this.checkbox.checked
  }

  check(checked) {
    this.checkbox.checked = checked
    this.checkbox.dispatchEvent(new Event("change"))
  }
}

class SearchFiltersManager {
  constructor(searchSidebar) {
    this._initializeFilters(searchSidebar)
  }

  activeFilters() {
    return this.filters().filter(filter => filter.active)
  }

  collapsibleFilters() {
    return this.filters().filter(filter => filter instanceof(CollapsibleFilter))
  }

  filters() {
    return Object.values(this.searchFilters)
  }

  _initializeFilters(searchSidebar) {
    this.searchFilters = {}

    searchSidebar.querySelectorAll(".search-filter").forEach(element => {
      const filter = this._initializeFilter(element)
      this.searchFilters[filter.id] = filter
    })
  }

  _initializeFilter(filterElement) {
    if (filterElement.classList.contains("date-filter")) {
      return new DateFilter(filterElement)
    } else if (filterElement.classList.contains("search-filter-collapsible")) {
      return new CollapsibleFilter(filterElement)
    } else {
      return new SearchFilter(filterElement)
    }
  }
}

class SearchFilter {
  constructor(element) {
    this.element = element
    this._initialize()
  }

  clear() {
    this.inputs.forEach(input => {
      if (input.type === "checkbox") {
        input.checked = false
      } else {
        input.value = ""
      }
    })
  }

  disable() {
    this.inputs.forEach(input => input.disabled = true)
  }

  visible() {
    return this.element.style.display !== "none"
  }

  _initialize() {
    this.id = this.element.id
    this.field = this.element.dataset.field
    this.active = this.element.dataset.active == "true"
    this.inputs = this.element.querySelectorAll("input")
  }
}

class CollapsibleFilter extends SearchFilter {
  constructor(element) {
    super(element)
    this._setupEvents()
  }

  // Override SearchFilter
  _initialize() {
    super._initialize()

    this.clearButton = this.element.querySelector(".js-search-filter-clear-button")
    this.removeButton = this.element.querySelector(".js-search-filter-remove-button")
  }

  toggle(display) {
    if (display) {
      this.element.style.display = ""
    } else {
      this.element.style.display = "none"
      this.clear()
    }

    this._toggleInputs(display)
  }

  _setupEvents() {
    this.clearButton.addEventListener("click", this._clearButtonClicked.bind(this))
    this.removeButton.addEventListener("click", this._removeButtonClicked.bind(this))
  }

  _clearButtonClicked() {
    this.clear()
  }

  _removeButtonClicked() {
    this.toggle(false)
  }

  _toggleInputs(display) {
    this.inputs.forEach(element => this._toggleElement(element, display))
  }

  _toggleElement(element, display) {
    if (display) {
      element.style.display = ""
    } else {
      element.style.display = "none"
    }

    element.disabled = !display
  }
}

class DateFilter extends CollapsibleFilter {
  // Override SearchFilter
  disable() {
    this.matcherSelect.disabled = true
    super.disable()
  }

  // Override CollapsibleFilter
  _initialize() {
    super._initialize()

    this.matcherSelect = this.element.querySelector(".date-filter-matcher-selector")

    this._initializeDateInputs()
  }

  _initializeDateInputs() {
    this.dateInputs = {}

    this.element.querySelectorAll("input.date-filter-input").forEach(input => {
      this._initializeDateRangePicker(input)
      this.dateInputs[input.dataset.matcher] = input
    })
  }

  _initializeDateRangePicker(input) {
    const $input = $(input)
    const defaultFormat = "MMM DD YYYY"
    const singleDatePicker = input.dataset.singleDatePicker == "true"

    $input.daterangepicker({
      autoUpdateInput: false,
      autoApply: singleDatePicker,
      singleDatePicker: singleDatePicker,
      showDropdowns: true,
      locale: {
        format: defaultFormat,
        cancelLabel: "Clear",
      },
      // bind the datepicker to the sidebar, instead of the body
      // this prevents the datepicker from moving when the body is scrolled
      parentEl: $(input).parent()
    })

    $input.on("apply.daterangepicker", function(_event, picker) {
      if (picker.singleDatePicker) {
        $(this).val(picker.startDate.format(defaultFormat))
      } else {
        $(this).val(`${picker.startDate.format(defaultFormat)} - ${picker.endDate.format(defaultFormat)}`)
      }
    });

    $input.on("cancel.daterangepicker", function(_event, picker) {
      $(this).val("");
    });
  }

  // Override CollpasibleFilter
  _setupEvents() {
    super._setupEvents()

    this.matcherSelect.addEventListener("change", this._matcherSelectChanged.bind(this))
  }

  _matcherSelectChanged() {
    this._toggleDateInputs(true)
  }

  _selectedMatcher() {
    return this.matcherSelect.options[this.matcherSelect.selectedIndex]
  }

  // Override CollpasibleFilter
  _toggleInputs(display) {
    this._toggleElement(this.matcherSelect, display)
    this._toggleDateInputs(display)
  }

  _toggleDateInputs(display) {
    // Hide and disable all date inputs
    this.inputs.forEach(element => this._toggleElement(element, false))

    if (display) {
      // Show and enable the date input that matches the selected matcher
      const selectedDateInput = this.dateInputs[this._selectedMatcher().value]
      this._toggleElement(selectedDateInput, display)
    }
  }
}

document.addEventListener("DOMContentLoaded", () => {
  const searchContainer = document.getElementById("search-container")

  if (searchContainer) {
    new SearchContainer(searchContainer)
  }
})
